// import axios from 'axios';
import axiosApi from '@/api/index';

let urlPath = '/api/term';

async function get() {
	return axiosApi.get(`${urlPath}`);
}
async function getOne(id) {
	return axiosApi.get(`${urlPath}/${id}`);
}

export default { get, getOne };
